import * as React from "react"
import styled from 'styled-components'
import { Helmet } from 'react-helmet'
import { graphql } from 'gatsby'
import { Logo, Grid, Layout, Panels, TrilingualCopy, Button, SocialBanner, Picture } from '@components'
import { palette, typeStyle } from '@theme'
import { useSiteDispatch } from '@utilities'


interface PageProps {
  data: {
    sanityPage: GatsbyTypes.SanityPage
  }
}


const AboutPage = ({ data: {
  sanityPage: {
    title,
    introduction,
    slidingPanels,
    aboutBottomBannerCopy,
    aboutBottomBannerImage,
  },
} }: PageProps) => {
  const dispatch = useSiteDispatch()
  return (
    <Layout backgroundColor={palette.lime} >
      <Helmet>
        <title>Bawi Agua Fresca: {title}</title>
      </Helmet>

      <Header>
        <StickyLogo >
          <Logo />
        </StickyLogo>
        <FloatingBanner>
          <TrilingualCopy>{introduction}</TrilingualCopy>
        </FloatingBanner>
      </Header>
      
      <Panels {...{ slidingPanels }} />

      <StyledGrid>
        <BottomBanner>
          <Copy>
            <TrilingualCopy>{aboutBottomBannerCopy}</TrilingualCopy>
          </Copy>
          <Button onClick={() => dispatch({ type: 'toggleProductShelf' })} shopNow />
          <Picture src={aboutBottomBannerImage?.asset?.url} columns={4} />
        </BottomBanner>
        <SocialBanner />
      </StyledGrid>
  
    </Layout>
  )
}


const Header = styled.div`
  position: relative;
  z-index: 0;
  min-height: 180vh;
  @media only screen and (min-width: 744px) {
    min-height: 180vh;
  }
  background-color: ${palette.pink};
`


const StickyLogo = styled.div`
  position: sticky;
  z-index: 1;
  top: 130px;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  
  grid-column-end: span 1;
  height: calc(100vh - 130px);
  padding: 0 5vw;
  @media only screen and (min-width: 744px) {
  top: 135px;
    height: 100vh;
    grid-column-end: span 12;
    padding: 0 10vw;
  }
  @media only screen and (min-width: 1440px) {
    padding: 0 144px;
  }
  
`


const FloatingBanner = styled.div`
  position: absolute;
  z-index: 2;
  bottom: 30vh;
  left: 5%;
  width: 90%;
  padding: 10vw 5vw;

  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  
  @media only screen and (min-width: 744px) {
    width: 60vw;
    left: 20vw;
    padding: 4vw 10vw;
  }
  @media only screen and (min-width: 1440px) {
    width: 1008px;
    left: 216px;
    padding: 58px 144px;
  }
  
  background: ${palette.lime};
  border: 2px solid black;
  box-shadow: 5px 5px 0px rgba(0, 0, 0, 1);
  @media only screen and (min-width: 744px) {
    box-shadow: 10px 10px 0px rgba(0, 0, 0, 1);
  }
  text-align: center;
  ${typeStyle.header}
  transform: translate3d(0, 0, 0);
  /* ^ fixes Safari glitch (still present 2022!) */
`


const StyledGrid = styled(props => <Grid {...props} />)`
  border-top: 1px solid black;
`


const BottomBanner = styled.div`
  position: relative;
  grid-column-end: span 1;
  height: 70vh;
  padding: 10vw 7vw 0 7vw;
  background-color: ${palette.tan};
  text-align: center;
  @media only screen and (min-width: 744px) {
    height: 50vw;
    grid-column-end: span 12;
    padding: 10vw 10vw 0 10vw;
  }
  @media only screen and (min-width: 1440px) {
    height: 720px;
    padding: 144px 144px 0 144px;
  }
  img {
    position: absolute;
    bottom: 0;
    width: 60vw;
    left: calc(20vw - 10px);
    @media only screen and (min-width: 744px) {
      width: 20vw;
      left: calc((100% - 20vw) / 2);
    }
    @media only screen and (min-width: 1440px) {
      width: 288px;
      left: calc((100% - 288px) / 2);
    }
  }
`

const Copy = styled.div`
  ${typeStyle.header}
  margin-bottom: 1em;
`



export const query = graphql`
  query AboutPageQuery {
    sanityPage(slug: {current: {eq: "about"}}) {
      ...pageFields
    }
  }
`


export default AboutPage
